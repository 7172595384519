/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.progressLineColor{
  background-color: none !important
}

.Button-download{
  background-image: linear-gradient(to right, #2A176D, #5A2DD5,#A687F7);
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  border: none !important;
  margin-top: 16px !important;
  padding: 8px  !important;
  border-radius: 8px !important;
}

.sign-up-btn{
  border-image: linear-gradient(to right, #2A176D,  #5A2DD5, #A687F7) 1 !important;
  border: 2px solid !important;
}

.loading-splash {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.loader-icon {
  margin-top: 16px;
  font-size: 48px;
  color: #1976d2;
}

.loading-splash .MuiCircularProgress-root {
  color: #1976d2;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.fade-out {
  animation: fadeIn 1s ease-in-out reverse;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
